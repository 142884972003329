import React from 'react';

export default () => {
  return (
    <svg viewBox="0 0 128 128" style={{ height: 'inherit' }}>
      <path
        fill="inherit"
        d="M52.864 64h23.28l-12.375-25.877zM63.81 1.026l-59.257 20.854 9.363 77.637 49.957 27.457 50.214-27.828 9.36-77.635-59.637-20.485zm-15.766 73.974l-7.265 18.176-13.581.056 36.608-81.079-.07-.153h-.064l.001-.133.063.133h.14100000000000001l.123-.274v.274h-.124l-.069.153 38.189 81.417-13.074-.287-8.042-18.58-17.173.082"
      />
    </svg>
  );
};
