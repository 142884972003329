export default {
  sections: {
    vision: 'vision',
    whyUs: 'whyUs',
    ourTeam: 'ourTeam',
    howItWorks: 'howItWorks',
    blog: 'blog',
    contactUs: 'contactUs',
  },
};
